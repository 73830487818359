import React, {useEffect} from 'react';
import { Button, Box, ButtonGroup } from "@mui/material";
import { useMetaMask } from "metamask-react";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { FaDiscord as DiscordIcon, FaCheckCircle as CheckIcon, FaSpinner as SpinnerIcon } from "react-icons/fa";
import { useAuth0 } from "@auth0/auth0-react";
import Communicator from "../../Communicator";
import "./claim-modal.css";
import IndicatableButton from "../indicatable-button/indicatable-button";
import {BigNumber} from "@ethersproject/bignumber";
import {formatEther} from "@ethersproject/units";
import {LoadingButton} from "@mui/lab";
import {ethers} from "ethers";
import Distributor from "../../abi/Distributor.json";
import {useMetamaskNetwork} from "../wallet-bar/wallet-bar";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function ClaimModal({text}) {
    // MODAL
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // METAMASK
    const { status, connect, account } = useMetaMask();


    // DISCORD
    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
    } = useAuth0();

    // CLAIM
    const [claim_in_progress, setClaimInProgress] = useState(false);
    const [is_claimed, setIsClaimed] = useState(false);
    const [is_eligible, setIsEligible] = useState(false);
    const [bonus_amount, setBonusAmount] = useState(BigNumber.from("0"));
    const { chain } = useMetamaskNetwork();

    const c = Communicator.getInstance();

    async function doRequestTokens(account) {
        return await c.claim(account);
    }
    async function doCheckEligibility(account) {
        return await c.verifyClaimEligibility(account);
    }
    async function doCheckWhitelistStatus(account) {
        try {
            return await c.getWhitelistStatus(account);
        } catch (e) {
            console.error(e);
            return {whitelisted: false, unclaimed: "0"};
        }
    }
    function requestTokens() {
        setClaimInProgress(true)
        doRequestTokens(account).then(() => {
            setIsClaimed(true);
            console.log(`Tokens claimed to ${account}`);
        }).catch((e) => {
            console.error(e);
        }).finally(() => {
            setClaimInProgress(false);
        });
    }
    function isEligibleForClaim() {
        setClaimInProgress(true);
        doCheckEligibility(account).then((ans) => {
            setIsEligible(ans);
            console.log("eligibility", ans);
        }).catch((e) => {
            setIsEligible(false);
            console.error(e);
        }).finally(() => {
            setClaimInProgress(false);
        });
    }
    async function doClaimBonus() {
        await setClaimInProgress(true)
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        const signer = provider.getSigner();
        const contract = new ethers.Contract(chain.DISTRIBUTOR_ADDRESS, Distributor.abi, provider);
        const tx = await contract.connect(signer).requestTokens();
        const tx_resp = await tx.wait();
        await setBonusAmount(0);
        await setClaimInProgress(false);
        return tx_resp;
    }
    function claimBonus() {
        doClaimBonus().catch((e) => console.error(e));
    }

    useEffect(async () => {
        let whitelist_status;
        if (account) whitelist_status = await doCheckWhitelistStatus(account);
        if (account && whitelist_status.whitelisted && BigNumber.from(whitelist_status.unclaimed) > 0) {
            setBonusAmount(whitelist_status.unclaimed);
        }
        if (!isAuthenticated || !account) return setIsEligible(false);
        if (await isEligibleForClaim(account)) return setIsEligible(true);
        setIsEligible(false);
    }, [is_claimed, account, isAuthenticated]);

    let alert = null;
    if (bonus_amount > 0) {
        alert = <Typography sx={{ mt: 2, fontWeight: "bold" }}>You are eligible to claim {formatEther(bonus_amount)} additional tokens.</Typography>
    } else if (is_claimed) {
        alert = <Typography color="red" sx={{ mt: 2 }}>Your tokens are on the way!</Typography>
    } else if (isAuthenticated && account && !claim_in_progress && !is_eligible) {
        alert = <Typography color="red" sx={{ mt: 2 }}>It looks like this address or Discord account has already claimed voting tokens.</Typography>
    }

    return (
        <div className="d-flex">
            <Button className="modal-open-btn center" variant="contained" onClick={handleOpen}>{text}</Button>
            <Modal
                className="modal"
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Claim your votes!
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Each user is entitled to set amount of voting tokens. Just follow this quick process so we know you're human.
                    </Typography>
                    {account &&
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Your votes will be sent to: <br /><strong>{account}</strong><br /> on the Harmony network.
                        </Typography>
                    }
                    {alert}
                    <Box
                        sx={{
                            display: 'flex',
                            '& > *': {
                                m: 1,
                            },
                        }}>
                        <ButtonGroup
                            orientation="vertical"
                            className="vertical-action-group" >
                            {bonus_amount <= 0 && <>
                                <Button
                                    endIcon={status === "connected" ? <CheckIcon/> : null}
                                    disabled={status === "connected" || status === "unavailable"}
                                    onClick={connect}>
                                    {status === "unavailable" ? "MetaMask is required." : "1. Connect MetaMask"}
                                </Button>
                                <Button
                                    endIcon={isAuthenticated ? <CheckIcon/> : <DiscordIcon/>}
                                    onClick={loginWithRedirect}
                                    disabled={isAuthenticated} >
                                    2. Verify with Discord
                                </Button>
                                <IndicatableButton
                                    endIcon={is_claimed || (!claim_in_progress && !is_eligible && account && isAuthenticated) ?
                                    <CheckIcon/> : null}
                                    disabled={!isAuthenticated || status !== "connected" || claim_in_progress || is_claimed || !is_eligible}
                                    onClick={requestTokens}
                                    loading={claim_in_progress}
                                    >
                                    3. Claim
                                </IndicatableButton>
                            </>}
                            {bonus_amount > 0 &&
                                <LoadingButton
                                    variant="contained"
                                    loading={claim_in_progress}
                                    onClick={claimBonus} >
                                    Claim Bonus
                                </LoadingButton>
                            }
                        </ButtonGroup>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
