//https://menubar.io/creating-a-material-ui-button-with-spinner-that-reflects-loading-state
import React from 'react'
import { Button, CircularProgress } from '@mui/material'
import { withStyles } from "@mui/styles";

const styles = {
    root: {
        marginLeft: 5
    }
}
const SpinnerAdornment = withStyles(styles)(props => (
    <CircularProgress
        className={props.classes.spinner}
        size={20}
    />
))
export default function IndicatableButton(props) {
    const {
        children,
        loading,
        ...rest
    } = props
    return (
        <Button {...rest}>
            {children}
            {loading && <SpinnerAdornment {...rest} />}
        </Button>
    )
}
