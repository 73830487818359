import React from 'react';
import "./wallet-bar.css";
import {Fab, Button, ButtonGroup, Avatar, Box, Divider} from "@mui/material";
import { useMetaMask } from "metamask-react";
import { useEffect, useState } from "react";
import BlockchainCrusaderVote from "../../abi/BlockchainCrusaderVote.json";
//import { useWeb3React } from '@web3-react/core'
// Pull in the shims (BEFORE importing ethers)
import "@ethersproject/shims"
// Import the ethers library
import { ethers, BigNumber, utils } from "ethers";
import {fromWei} from "web3-utils";
import token_logo from "../../assets/token-logo.ico";

export const Chain = {
    Harmony: {
        ID: 1666600000,
        NAME: "Harmony",
        TOKEN: "ONE",
        RPC: "https://api.harmony.one",
        BCV_ADDRESS: process.env.REACT_APP_MAINNET_TOKEN,
        DISTRIBUTOR_ADDRESS: process.env.REACT_APP_MAINNET_DISTRIBUTOR,
        VOTE_HANDLER_ADDRESS: process.env.REACT_APP_MAINNET_VOTE_HANDLER,
        COLLECTIBLE_ADDRESS: process.env.REACT_APP_MAINNET_COLLECTIBLE,
    },
    HarmonyTestnet: {
        ID: 1666700000,
        NAME: "Harmony Testnet",
        TOKEN: "ONE",
        RPC: "https://api.s0.b.hmny.io",
        BCV_ADDRESS: process.env.REACT_APP_TESTNET_TOKEN,
        DISTRIBUTOR_ADDRESS: process.env.REACT_APP_TESTNET_DISTRIBUTOR,
        VOTE_HANDLER_ADDRESS: process.env.REACT_APP_TESTNET_VOTE_HANDLER,
        COLLECTIBLE_ADDRESS: process.env.REACT_APP_TESTNET_COLLECTIBLE,
    },
    Local: {
        ID: 1337,
        NAME: "Localhost",
        TOKEN: "ETH",
        RPC: "https://localhost:8545",
        BCV_ADDRESS: process.env.REACT_APP_LOCAL_TOKEN,
        DISTRIBUTOR_ADDRESS: process.env.REACT_APP_LOCAL_DISTRIBUTOR,
        VOTE_HANDLER_ADDRESS: process.env.REACT_APP_LOCAL_VOTE_HANDLER,
        COLLECTIBLE_ADDRESS: process.env.REACT_APP_LOCAL_COLLECTIBLE,
    },
};
export const SUPPORTED_CHAIN_IDS = Object.values(Chain).map((elm) => elm.ID);

function unknownChain(id) {
    return {
        ID: id,
        NAME: "Unknown",
        TOKEN: "???",
    };
}

function TokenBalance({balance, prefix, account}) {
    let pre;
    if (prefix) {
        pre = <>
                <span>{prefix}</span>
                <Divider orientation="vertical" flexItem />
            </>
    }
    return (
        <>
            {pre}
            <span>{account.substr(0, 5)}...{account.substr(account.length - 4, account.length)}</span>
            <Divider orientation="vertical" flexItem />
            <Avatar alt="VOTE Tokens" src={token_logo} />
            <span>{balance} VOTE</span>
        </>
    );
}

export function useMetamaskNetwork() {
    console.log("useMetamaskNetwork");
    const { status, connect, account } = useMetaMask();
    const [chain, setChain] = useState();
    const is_connected = status === "connected";

    function doGetActiveChain() {
        window.ethereum.request({ method: 'eth_chainId' })
            .then( (_chainId) => {
                const chain_id = parseInt(_chainId, 16);
                let chain = Object.values(Chain).find((elm) => elm.ID == chain_id);
                if (!chain) chain = unknownChain(chain_id);
                return onChainUpdate(chain);
            }).catch( (e) => {
                console.error(e);
            });
    }

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (!is_connected) return;
            await doGetActiveChain();
        }, 2000);
        return () => clearInterval(timer);
    });

    useEffect(() => {
        console.log("Inside useMetamaskNetwork:useEffect")
        if (!is_connected) return;
        window.ethereum.on('chainChanged', (_chainId) => {
            const chain_id = parseInt(_chainId, 16);
            let chain = Object.values(Chain).find((elm) => elm.ID == chain_id);
            if (!chain) {
                chain = unknownChain(chain_id);
            }
            onChainUpdate(chain).then(r => {console.log("Chain updated")});
        });
    }, [is_connected, ]);

    async function onChainUpdate(chain) {
        await setChain(chain);
        return chain;
    }

    return {chain, setChain, isSupportedNetwork: () => chain && SUPPORTED_CHAIN_IDS.includes(chain.ID)};
}

function MetaMaskStatus() {
    const { status, connect, account } = useMetaMask();
    const [chain, setChain] = useState();
    const [balance, setTokenBalance] = useState();
    const is_connected = status === "connected";

    async function doUpdateBalance(_chain) {
        let local_chain = _chain || chain;
        if (!local_chain) return;
        let updated_balance = BigNumber.from(0);
        if (is_connected) updated_balance = await getTokenBalance();
        setTokenBalance(updated_balance);
    }

    async function onChainUpdate(chain) {
        await setChain(chain);
        return chain;
    }

    useEffect(() => {
        if (!is_connected) return;
        return doUpdateBalance();
    }, []);

    useEffect(() => {
        const timer = setInterval(async () => {
                if (!is_connected) return;
                await doUpdateBalance();
            }, 5000);
        return () => clearInterval(timer);
    }, [is_connected, doUpdateBalance]);

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (!is_connected) return;
            await doGetActiveChain();
        }, 2000);
        return () => clearInterval(timer);
    }, [doGetActiveChain]);

    useEffect(() => {
        if (!is_connected) return;
        window.ethereum.on('chainChanged', (_chainId) => {
            const chain_id = parseInt(_chainId, 16);
            let chain = Object.values(Chain).find((elm) => elm.ID == chain_id);
            if (!chain) {
                chain = unknownChain(chain_id);
            }
            onChainUpdate(chain).then(r => {console.log("Chain updated")});
        });
        window.ethereum.on('accountsChanged', () => {
            doUpdateBalance().then(r => {console.log("Balance updated")});
        });
    }, [is_connected, doUpdateBalance]);

    async function doGetActiveChain() {
        window.ethereum.request({ method: 'eth_chainId' })
            .then( (_chainId) => {
                const chain_id = parseInt(_chainId, 16);
                let chain = Object.values(Chain).find((elm) => elm.ID == chain_id);
                if (!chain) chain = unknownChain(chain_id);
                return onChainUpdate(chain);
            }).then( (_chain) => {
                return doUpdateBalance(_chain);
            })
            .catch( (e) => {
                console.error(e);
            });
    }

    async function getTokenBalance() {
        if (!chain || !chain.BCV_ADDRESS) {
            console.error("Unsupported network or missing BCV contract address");
            return BigNumber.from(0);
        }
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const contract = new ethers.Contract(chain.BCV_ADDRESS, BlockchainCrusaderVote.abi, provider);
        try {
            return await contract.balanceOf(account);
        } catch (e) {
            console.error(e);
            return BigNumber.from(0);
        }
    }

    let variant = "contained";
    let body;
    let display_balance = balance ? Number(fromWei(balance.toString())).toFixed(4) : "0";
    console.log("Display balance", display_balance);
    let other_buttons = [];

    if (chain && !SUPPORTED_CHAIN_IDS.includes(chain.ID)) {
        body = <span>Unsupported network</span>
    } else if (status === "initializing") {
        body = <span>Syncing...</span>;
    } else if (status === "unavailable") {
        body = <span>MetaMask not available</span>
    } else if (status === "notConnected") {
        body = <span>Connect to MetaMask</span>
    } else if (!chain || status === "connecting") {
        body = <div>Connecting...</div>
    } else if (status === "connected") {
        let testnet_prefix = "";
        if (chain === Chain.HarmonyTestnet) testnet_prefix = "TSTNET";
        if (chain === Chain.Local) testnet_prefix = "LOCAL";
        body = <TokenBalance prefix={testnet_prefix} account={account} balance={display_balance} />;
    }

    return (
        <Box
            className="wallet-bar"
            onClick={is_connected ? null : connect}
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                bgcolor: 'background.paper',
                color: 'text.secondary',
                '& svg': {
                    m: 1.5,
                },
                '& hr': {
                    mx: 0.5,
                },
            }}
        >
            {body}
        </Box>
    );
}

export default function WalletBar() {
    return (
        <MetaMaskStatus />
    );
}
