import React, {createRef, useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Typography,
    TextField,
    InputAdornment,
    Chip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SearchIcon from '@mui/icons-material/Search';
import Communicator from "../../Communicator";
import _ from "lodash";
import WalletBar, {useMetamaskNetwork} from "../wallet-bar/wallet-bar";
import {useMetaMask} from "metamask-react";
import {formatEther, parseEther} from "ethers/lib/utils";
import {BigNumber, ethers} from "ethers";
import VoteHandler from "../../abi/VoteHandler.json";
import BlockchainCrusadersVote from "../../abi/BlockchainCrusaderVote.json";
import {fromWei, toWei} from "web3-utils";
import Collectible from "../../abi/Collectible.json";
import TokenLogo from "../../assets/logo-bare.png";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function Win() {
    let [action_pending, setActionPending] = useState(false);
    let [is_registered, setIsRegistered] = useState(false);
    let [address, setAddress] = useState("");

    function register() {
        async function doRegister() {
            setActionPending(true);
            const c = Communicator.getInstance();
            const a = await c.giveawayRegistration(address);
        }
        doRegister()
            .then(() => setIsRegistered(true))
            .catch(e => { console.error(e); alert(e.message); })
            .finally(() => setActionPending(false));
    }

    function onChange(event) {
        let v = event.target.value.toString();
        setAddress(v);
    }

    return (
        <>
            <Box className="vote section" >
                <WalletBar />
                <Box sx={{ mt: "100px", width: "60%", maxWidth: "600px"}}>
                    <Box sx={{bgcolor: "white", p: "20px"}}>
                        <TextField fullWidth label="ONE Address" id="address" value={address} onChange={onChange} />
                        <Box sx={{ display: "flex" }}>
                        <LoadingButton
                            loading={action_pending}
                            disabled={is_registered}
                            onClick={register} variant="contained" sx={{ mt: 2, ml: "auto", mr: "auto"}}>
                            Register
                        </LoadingButton>
                        </Box>
                    </Box>
                    <Box sx={{ m: "0", p: "0", bgcolor: "white"}}>
                        <h3 className="center">Enter to win an NFT!</h3>
                        <p className="center">To be eligible, enter your address above.</p>
                        <p className="center">Each crusader you own at the time of the giveaway will count as an additional chance to win! Visit our <a href="/mint">mint</a> page to build your collection.</p>
                        <img className="desktop"
                            src="https://storage.googleapis.com/cdn.nftkey.app/nft-collections/blockchaincrusaders/0xfe5073e37ab5e1b80c1bce773faf71d1cf0eb0cca1e1eb44de2fe2653cc8b1e8"
                            width="600"
                            height="740"
                            />
                    </Box>
                </Box>
            </Box>

            <Snackbar open={is_registered} autoHideDuration={0}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Your address has been entered into the giveaway!
                </Alert>
            </Snackbar>
        </>
    );
}
