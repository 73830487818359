import "./mint.css";
import React, {createRef, useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Typography,
    TextField,
    InputAdornment,
    Chip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SearchIcon from '@mui/icons-material/Search';
import Communicator from "../../Communicator";
import _ from "lodash";
import WalletBar, {useMetamaskNetwork} from "../wallet-bar/wallet-bar";
import {useMetaMask} from "metamask-react";
import {formatEther, parseEther} from "ethers/lib/utils";
import {BigNumber, ethers} from "ethers";
import VoteHandler from "../../abi/VoteHandler.json";
import BlockchainCrusadersVote from "../../abi/BlockchainCrusaderVote.json";
import {fromWei, toWei} from "web3-utils";
import Collectible from "../../abi/Collectible.json";
import TokenLogo from "../../assets/logo-bare.png";

function handleMetaMaskError(e) {
    console.error(e);
    if (e.data && e.data.message) {
        alert(e.data.message);
    }
}

function MintCard({}) {
    const [num_to_mint, setVoteInput] = useState("");
    const [action_pending, setActionPending] = useState(false);
    const [remaining_supply, setRemainingSupply] = useState("...");
    const [mint_text, setMintText] = useState("Mint");

    const { status, connect, account } = useMetaMask();
    const { chain, isSupportedNetwork } = useMetamaskNetwork();

    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = provider.getSigner();

    const PRICE = 500;

    function finallyTransaction() {
        setActionPending(false);
    }

    useEffect(() => {
        if (!chain || !isSupportedNetwork()) return;
        const contract = new ethers.Contract(chain.COLLECTIBLE_ADDRESS, Collectible.abi, provider);
        contract.connect(signer).callStatic.availableSupply().then((supply) => {
            setRemainingSupply(supply.toString());
        });
    }, [action_pending, remaining_supply, provider, signer, chain]);

    function mint() {
        async function doMint() {
            await setActionPending(true);
            const contract = new ethers.Contract(chain.COLLECTIBLE_ADDRESS, Collectible.abi, provider);
            const price = await contract.connect(signer).callStatic.price();
            const mint_price = (BigNumber.from(price.toString()) * BigNumber.from(parseInt(num_to_mint))).toLocaleString('fullwide', { useGrouping: false });
            const tx = await contract.connect(signer).mintNFTs(num_to_mint, {value: mint_price});
            await tx.wait();
        }
        doMint().catch(handleMetaMaskError).finally(finallyTransaction);
    }

    function onChange(event) {
        let v = event.target.value.toString().replace(/[^0-9.]/g, "");
        let n = parseInt(v);
        if (n > 10) {
            v = "10";
        } else if (n < 0) {
            v = "1";
        }
        n = parseInt(v);
        if (v && !isNaN(n)) {
            const amount = PRICE * n;
            setMintText(`Mint ${n} for ${amount} ONE`);
        } else {
            setMintText("Mint");
        }
        setVoteInput(v);
    }

    return (
    <Card className="vote-option" sx={{ display: 'flex', mb: "20px" }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto', width: "150px" }}>
                <Typography component="div" variant="h5">
                    Public Mint
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">

                </Typography>
            </CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', pl: 2, pb: 1 }}>
                <Typography component="div" variant="subtitle1" >
                    {remaining_supply} remaining
                </Typography>
            </Box>
        </Box>
        <CardMedia
            component="img"
            sx={{ width: 151, height: 151, mr: "25px" }}
            image={TokenLogo}
            alt=""
        />
        <CardContent>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "right" }} >
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", minWidth: "fit-content" }}>
                    <TextField type="text" label="Amount to Mint" variant="outlined" value={num_to_mint} onChange={onChange} />
                </Box>
                <LoadingButton
                    loading={action_pending}
                    onClick={mint} variant="contained" sx={{ mt: 2 }}
                    disabled={!isSupportedNetwork()}>
                    {isSupportedNetwork() ? mint_text : "Change to Harmony Network"}
                </LoadingButton>
            </Box>
        </CardContent>
    </Card>
    );
}

export default function Mint() {
    return (
        <Box className="vote section" >
            <WalletBar />
            <Box sx={{ mt: "100px" }}>
                <MintCard />
            </Box>
        </Box>
    );
}
