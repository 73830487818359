import React from 'react';
import PropTypes from 'prop-types';
import {Paper} from "@mui/material";
import "./number-card.css";

NumberCard.propTypes = {
    index: PropTypes.number,
    content: PropTypes.element,
};

function NumberCard({index, content, tag}) {
    let end = "";
    if (typeof tag === "string") {
        end = (
            <>
                <hr/>
                <span className="italic">{tag}</span>
            </>
        );
    } else {
        end = (
            <>
                <hr/>
                {tag}
            </>
        );
    }
    return (
        <Paper className="paper" elevation={20}>
            <div className="w-100"><h1>{index}</h1></div>
            <hr/>
            <span>{content}</span>
            {end}
        </Paper>
    );
}

export default NumberCard;
