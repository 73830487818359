import './App.css';
import Landing from "./components/landing/landing";
import Vote from "./components/vote/vote";
import { MetaMaskProvider } from "metamask-react";
import { SUPPORTED_CHAIN_IDS } from "./components/wallet-bar/wallet-bar";
import { Web3ReactProvider} from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector"
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Mint from "./components/mint/mint";
import Win from "./components/win/win";
import ManualStick from "./components/stick/stick";

const MetaMask = new InjectedConnector({ supportedNetworks: SUPPORTED_CHAIN_IDS });

const config = getConfig();
const web2ProviderConfig = {
    domain: config.domain,
    clientId: config.clientId,
    ...(config.audience ? { audience: config.audience } : null),
    redirectUri: window.location.origin,
};

function App() {
    return (
        <BrowserRouter>
            <Auth0Provider {...web2ProviderConfig}>
                <MetaMaskProvider>
                    <Web3ReactProvider
                        connectors={[MetaMask]}
                        libraryName="ethers.js" >
                        <Routes>
                            <Route path="/" element={<Landing />} />
                            <Route path="vote" element={<Vote />} />
                            <Route path="mint" element={<Mint />} />
                            <Route path="win" element={<Win />} />
                            <Route path="stick" element={<ManualStick />} />
                        </Routes>
                    </Web3ReactProvider>
                </MetaMaskProvider>
            </Auth0Provider>
        </BrowserRouter>
    );
}

export default App;
