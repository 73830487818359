import "./vote.css";
import React, {createRef, useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Typography,
    TextField,
    InputAdornment,
    Chip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SearchIcon from '@mui/icons-material/Search';
import Communicator from "../../Communicator";
import _ from "lodash";
import WalletBar, {useMetamaskNetwork} from "../wallet-bar/wallet-bar";
import {useMetaMask} from "metamask-react";
import {formatEther, parseEther} from "ethers/lib/utils";
import {BigNumber, ethers} from "ethers";
import VoteHandler from "../../abi/VoteHandler.json";
import BlockchainCrusadersVote from "../../abi/BlockchainCrusaderVote.json";
import {toWei} from "web3-utils";
import BlockchainCrusaderVote from "../../abi/BlockchainCrusaderVote.json";

function tokenComparator(a, b) {
    return BigNumber.from(b.votes) - BigNumber.from(a.votes);
}

function handleMetaMaskError(e) {
    console.error(e);
    if (e.data && e.data.message) {
        alert(e.data.message);
    }
}

function Search({onChange}) {
    let pending_timeout = null;
    function debouncer(e) {
        if (pending_timeout)
            clearInterval(pending_timeout);
        pending_timeout = setTimeout(() => {
                onChange(e);
            }, 250);
    }

    return (
        <Card className="search-container">
                <TextField
                    label="Search"
                    sx={{ m: 1, width: '25ch' }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                    }}
                    onChange={debouncer}
                    variant="outlined" fullWidth />
        </Card>
    );
}

function VoteOption({name, ticker, identifier, voteCount, icon, actionText, actionDisabled, maxVotes, chain, allowance, postTransactionHandler}) {
    const [vote_input, setVoteInput] = useState("");
    const [action_pending, setActionPending] = useState(false);
    const [action_text, setActionText] = useState(actionText);
    const [button_action, setButtonAction] = useState("vote");

    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = provider.getSigner();

    function finallyTransaction() {
        setActionPending(false);
        if (postTransactionHandler)
            postTransactionHandler();
    }

    function vote() {
        async function doVote() {
            await setActionPending(true);
            console.log(`Sending ${vote_input} votes for ${identifier}`);
            const contract = new ethers.Contract(chain.VOTE_HANDLER_ADDRESS, VoteHandler.abi, provider);
            const tx = await contract.connect(signer).vote(toWei(vote_input.toString()), identifier);
            await tx.wait();
        }
        doVote().catch(handleMetaMaskError).finally(finallyTransaction);
    }

    function approve() {
        async function doApprove() {
            await setActionPending(true);
            const APPROVAL_AMOUNT = toWei("1000000");
            const contract = new ethers.Contract(chain.BCV_ADDRESS, BlockchainCrusadersVote.abi, provider);
            const tx = await contract.connect(signer).approve(chain.VOTE_HANDLER_ADDRESS, APPROVAL_AMOUNT);
            await tx.wait();
        }
        doApprove().catch(handleMetaMaskError).finally(finallyTransaction);
    }

    function setMaximumVotes(evt) {
        setVoteInput(maxVotes);
    }

    function onChange(event) {
        const v = event.target.value.toString().replace(/[^0-9.]/g, "");
        setVoteInput(v);
    }

    useEffect(() => {
        // Override action text if allowance is too small
        if (allowance <= 0 || (_.isNumber(vote_input) && allowance < toWei(vote_input.toString())) ) {
            setActionText("Approve");
            setButtonAction("approve");
        } else {
            setActionText(actionText);
            setButtonAction("vote");
        }
    }, [action_text, allowance, vote_input]);


    return (
    <Card className="vote-option" sx={{ display: 'flex', mb: "20px" }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto', width: "150px" }}>
                <Typography component="div" variant="h5">
                    {name}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                    {ticker}
                </Typography>
            </CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', pl: 2, pb: 1 }}>
                <Typography component="div" variant="subtitle1" >
                    {parseFloat(formatEther(voteCount)).toFixed(4) || 0} votes
                </Typography>
            </Box>
        </Box>
        <CardMedia
            component="img"
            sx={{ width: 151, height: 151, mr: "25px" }}
            image={icon}
            alt=""
        />
        <CardContent>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "right" }} >
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", minWidth: "fit-content" }}>
                    <TextField type="text" label="VOTE allocation" variant="outlined" value={vote_input} onChange={onChange} />
                    <Chip
                        label="max"
                        aria-label="allocate all votes"
                        onClick={setMaximumVotes}
                        onMouseDown={setMaximumVotes}
                        sx={{ ml: 1 }}
                    />
                </Box>
                <LoadingButton
                    loading={action_pending}
                    onClick={button_action === "vote" ? vote : approve} variant="contained" sx={{ mt: 2 }}
                    disabled={actionDisabled || (!vote_input && button_action === vote)}>
                        {action_text}
                </LoadingButton>
            </Box>
        </CardContent>
    </Card>
    );
}

function SearchableVoteOptionsList() {
    const { status, connect, account } = useMetaMask();
    const is_connected = status === "connected";
    const { chain, isSupportedNetwork } = useMetamaskNetwork();
    const [max_votes, setMaxVotes] = useState(0);

    const [allowance, setAllowance] = useState(false);
    const [token_list, setTokenList] = useState([]);
    const LOAD_INTERVAL = 10;
    const [has_more, setHasMore] = useState(true);
    const [filtered_token_list, setFilteredTokenList] = useState(token_list);
    const [active_token_list, setActiveTokenList] = useState(_.take(token_list, LOAD_INTERVAL));
    const [transaction_count, setTransactionCount] = useState(0);
    //let active_token_list = _.take(token_list, 10);

    async function initialLoad() {
        const manifest = await Communicator.getInstance().getTokenManifest();
        console.log("Manifest", manifest);
        manifest.sort(tokenComparator);
        await setTokenList(_.cloneDeep(manifest));
        await setActiveTokenList(_.take(manifest, LOAD_INTERVAL));
        await setFilteredTokenList(manifest);
    }

    useEffect(() => {
        function allowanceWrapper() {
            async function fetchApprovalAllowance() {
                const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
                const signer = provider.getSigner();
                const contract = new ethers.Contract(chain.BCV_ADDRESS, BlockchainCrusadersVote.abi, provider);
                const allowance = await contract.connect(signer).callStatic.allowance(account, chain.VOTE_HANDLER_ADDRESS);
                setAllowance(allowance);
                console.log(`Allowance maximum is ${allowance} for ${account}`);
                return allowance;
            }

            if (chain && account)
                fetchApprovalAllowance().catch(e => console.error(e));
            else {
                setTimeout(allowanceWrapper, 1000);
            }
        }
        allowanceWrapper();
    }, [chain, account, transaction_count]);

    useEffect(() => {
        initialLoad().then(console.log("Loaded token manifest")).catch((e) => console.error(e));
    }, [transaction_count]);

    useEffect(() => {
        if (has_more && active_token_list.length >= filtered_token_list.length) {
            console.log("No more", `Loaded: ${active_token_list.length}, Available: ${filtered_token_list.length}`);
            setHasMore(false);
        } else if (!has_more) {
            setHasMore(true);
        }
    }, [active_token_list, filtered_token_list]);

    useEffect( () => {
        async function getTokenBalance() {
            if (!chain || !chain.BCV_ADDRESS) {
                console.error("Unsupported network or missing BCV contract address");
                return BigNumber.from(0);
            }
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const contract = new ethers.Contract(chain.BCV_ADDRESS, BlockchainCrusaderVote.abi, provider);
            try {
                return await contract.balanceOf(account);
            } catch (e) {
                console.error(e);
                return BigNumber.from(0);
            }
        }
        getTokenBalance().then((amt) => setMaxVotes(formatEther(amt))).catch((e) => console.error(e));
    }, [chain, account, transaction_count, is_connected]);

    function loadNext() {
        console.log("Loading more...");
        setActiveTokenList(_.take(filtered_token_list, active_token_list.length + LOAD_INTERVAL));
    }

    function onSearch(event) {
        const q = event.target.value.toLowerCase();
        console.log("Search query", q);
        let filtered_list = token_list;
        if (q) {
            filtered_list = token_list.filter((elm) => {
                return elm.name.toLowerCase().startsWith(q) || elm.ticker.toLowerCase().startsWith(q);
            });
        }
        setFilteredTokenList(filtered_list);
        setActiveTokenList(_.take(filtered_list, LOAD_INTERVAL));
    }

    function postTransactionHandler() {
        setTransactionCount(transaction_count + 1);
    }

    let vote_action = "Vote";
    let vote_disabled = false;
    if (!is_connected) {
        vote_action = "Connect to MetaMask";
        vote_disabled = true;
    } else if (!isSupportedNetwork()) {
        vote_action = "Change to Harmony Network";
        vote_disabled = true;
    } else if (!allowance) {
        vote_action = "Approve";
        vote_disabled = false;
    }

    function toVoteOptions(lst) {
        lst.sort(tokenComparator);
        return lst.map( ({ticker, name, image, votes, identifier}) => {
            return <VoteOption key={ticker} name={name} ticker={ticker} identifier={identifier} voteCount={votes} percent={0} icon={image} actionText={vote_action} actionDisabled={vote_disabled} maxVotes={max_votes} chain={chain} allowance={allowance} postTransactionHandler={postTransactionHandler} />
        });
    }
    return (
        <Box sx={{ display: "block", flexDirection: "column", alignItems: "center"}}>
            <Search onChange={onSearch} />
            <InfiniteScroll next={loadNext} hasMore={has_more} loader={<p>Loading...</p>} dataLength={active_token_list.length}>
                {toVoteOptions(active_token_list)}
            </InfiniteScroll>
        </Box>
    );
}

export default function Vote() {
    return (
        <Box className="vote section" >
            <WalletBar />
            <Box sx={{ mt: "100px" }}>
                <SearchableVoteOptionsList />
            </Box>
        </Box>
    );
}
