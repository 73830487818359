import {Auth0Client, Auth0ClientOptions} from "@auth0/auth0-spa-js";
import axios from "axios";
import { getClientConfig } from "./config";

export default class Communicator {
    constructor(_host) {
        const host = _host || Communicator.BASE_URL;
        const config = getClientConfig();
        this.auth = new Auth0Client(config);
        if (!host) {
            throw new CommunicatorException({code: CommunicatorException.Error.UNDEFINED_API_HOSTNAME, message: "API hostname was undefined, I don't know where to send the request"});
        }
        this.host = host;
    }

    buildUri(path) {
        console.log("uri", `${this.host}${path}`);
        return `${this.host}${path}`;
    }

    async getToken() {
        try {
            return await this.auth.getTokenSilently();
        } catch (e) {
            if (e.message.toLowerCase().includes("login required")) {
                throw new CommunicatorException({code: CommunicatorException.Error.LOGIN_REQUIRED, message: "User must be logged in to perform this operation"});
            }
            throw e;
        }
    }

    async _get(path) {
        const token = await this.getToken();
        const axios_config = {
            headers: { Authorization: `Bearer ${token}`},
        };
        const uri = this.buildUri(path);

        return await axios.get(uri, axios_config);
    }

    async myself() {
        const path = "/myself";
        return this._get(path);
    }

    async claim(account) {
        const path = `/token/issue/${account}`;
        return await this._get(path);
    }

    async verifyClaimEligibility(account) {
        const path = `/token/issue/verify/${account}`;
        const raw = await this._get(path);
        return JSON.parse(raw.data);
    }

    async getTokenManifest() {
        const path = "/vote/manifest";
        const raw = await axios.get(this.buildUri(path));
        return JSON.parse(JSON.stringify(raw.data));
    }

    async getWhitelistStatus(account) {
        const path = `/token/claim/verify/${account}`;
        const raw = await axios.get(this.buildUri(path));
        return raw.data;
    }

    async giveawayRegistration(account) {
        const path = `/win/register/${account}`;
        const raw = await axios.get(this.buildUri(path));
        return raw.status == 200;
    }

    static getInstance() {
        return new Communicator(Communicator.BASE_URL);
    }
}
Communicator.BASE_URL = process.env.REACT_APP_BC_API_HOST;

export class CommunicatorException {
    constructor({code, message, expected, actual}) {
        this.code = code;
        this.message = message;
        this.expected = expected;
        this.actual = actual;
    }

    toString() {
        let str = `${this.code} => ${this.message}`;
        if (this.expected !== undefined) {
            str += ` expected ${this.expected}`;
        }
        if (this.actual !== undefined) {
            str += ` got value ${this.actual}`;
        }
        return str;
    }
}
CommunicatorException.Error = {
    LOGIN_REQUIRED: "ERR_LOGIN_REQUIRED",
    UNDEFINED_API_HOSTNAME: "ERR_UNDEFINED_API_HOSTNAME",
};
