import React, {useState} from "react";
import {Box, Button, TextareaAutosize, TextField, Typography} from "@mui/material";
import {ethers} from "ethers";

export default function ManualStick() {
    const [destination, setDestination] = useState("");
    const [abi, setABI] = useState("");
    const [method, setMethod] = useState("");
    const [argv, setArgv] = useState("");
    const [result_display, setResultDisplay] = useState("");

    function handleAddress(evt) {
        setDestination(evt.target.value);
    }
    function handleABI(evt) {
        setABI(evt.target.value);
    }
    function handleMethod(evt) {
        setMethod(evt.target.value);
    }
    function handleArgs(evt) {
        setArgv(evt.target.value);
    }

    function submit() {
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        const signer = provider.getSigner();
        const contract = new ethers.Contract(destination, abi, provider);
        const args = JSON.parse(argv);
        contract.connect(signer)[method](...args).then((resp) => {
            console.log(resp);
            setResultDisplay(JSON.stringify(resp));
        }).catch(e => {
            console.error(e);
            setResultDisplay(JSON.stringify(e));
        });
    }

    return (
        <Box>
            <Box sx={{ backgroundColor: "white"}}>
                <TextField type="text" label="Contract Address" variant="outlined" onChange={handleAddress} /><br/>
                <TextField type="text" label="ABI" variant="outlined" onChange={handleABI} /><br/>
                <TextField type="text" label="Method" variant="outlined" onChange={handleMethod} /><br/>
                <TextField type="text" label="Arguments" variant="outlined" onChange={handleArgs} /><br/>
                <Button onClick={submit}>Submit</Button>
            </Box>

            <Typography>{result_display}</Typography>
        </Box>
    )
}