import React, { useState } from 'react';
import './landing.css';
import { Bounce, Fade, AttentionSeeker } from 'react-awesome-reveal';
import { Link } from 'react-scroll';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import {Stack, ImageList, ImageListItem, Button, Box} from "@mui/material";
import Icon from "@mui/material/Icon"
import primary_bg from '../../assets/home-section.png';
import NumberCard from "../number-card/number-card";
import WalletBar from "../wallet-bar/wallet-bar";
import {FaDiscord as DiscordIcon, FaTwitter as TwitterIcon, FaInstagram as InstaIcon} from "react-icons/fa";
import crusader_a from "../../assets/crusaders/a.png";
import crusader_b from "../../assets/crusaders/b.png";
import crusader_c from "../../assets/crusaders/c.png";
import crusader_d from "../../assets/crusaders/d.png";
import ClaimModal from "../claim-modal/claim-modal";

const crusader_images = [crusader_a, crusader_b, crusader_c, crusader_d];

function Scroll({to, absolute}) {
    return (
        <div className={absolute ? "scroll-down-absolute" : "scroll-down"}>
            <Link
                activeClass="active"
                to={to}
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
            >
                <Bounce>
                    <ArrowCircleDownIcon
                        fontSize="large"
                        style={{ pointerEvents: 'fill', cursor: 'pointer' }}
                    />
                </Bounce>
            </Link>
        </div>
    );
}

function IconLink({Icon, link}) {
    return (
        <a className="contact-icon" href={link} target="_blank" rel="noopener"><Icon sx={{ fontSize: 100 }} size={100} color="white" /></a>
    );
}

function Quote({quote, author}) {
    return (
        <div className="subtitle">
            <div><span className="quote">{quote}</span></div>
            <div><span className="author">~{author}</span></div>
        </div>
    )
}

const Landing = () => {
    return (
        <>
            <div className="home-wrapper primary">
                <div className="home section">
                    <WalletBar />
                    <img className="full-screen" src={primary_bg} alt="" />
                    <div className="section-header">
                        <Fade bottom distance="40px">
                            <h1>
                                Blockchain Crusaders
                            </h1>
                            <Quote quote="Any crusade requires optimism and the ambition to aim high." author="Paul Allen" />
                            <Box><Button variant="contained" className="center btn-bright" href="/mint">Mint Now!</Button></Box>
                            <Scroll to="about" />
                        </Fade>
                    </div>
                </div>
                <div id="about" className="section">
                    <div>
                        <Fade>
                            <h1 className="center">Welcome</h1><br/>
                            <div className="intro-paragraph">
                                <p className="center">Blockchain Crusaders is a collection of unique, <strong>hand-drawn NFTs</strong> on the <strong>Harmony</strong> blockchain that combines the aesthetic anime-look with passionate cryptocurrency fandom.  With an innovative minting process, <strong>community members have a say</strong> on which cryptocurrencies get featured on the NFT pieces via vote.  Your Crusader doubles as your membership card and grants access to <strong>members-only benefits</strong> and perks.</p>
                                <p className="center bigger-font"><strong>Blockchain Crusaders isn't just an NFT, it's a community.</strong></p>
                                <div className="centering-container medium-link">
                                    <Button variant="contained" className="center" target="_blank" href="https://medium.com/@dio_15292?p=1bcfa20018d5">Read our Medium article</Button>
                                </div>
                            </div>
                        </Fade>
                        <Scroll to="overview" />
                    </div>
                </div>
                <div id="overview" className="section">
                    <div>
                        <Fade><h1 className="center">Our unique process.</h1><br/></Fade>
                        <AttentionSeeker effect="pulse">
                            <Stack className="stack" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                <NumberCard index={1} content="Claim free voting tokens by linking your Discord account." tag={<ClaimModal text="Claim" />} />
                                <NumberCard index={2} content="Burn tokens to vote for your favorite crypto." tag=
                                    {<Box className="d-flex"><Button variant="contained" className="modal-open-btn center" href="/vote">Vote</Button></Box>}
                                />
                                <NumberCard index={3} content="The 10 cryptocurrencies with the most votes will appear on our limited, hand-drawn NFTs." tag="Complete" />
                                <NumberCard index={4} content={<div><strong>Mint day!</strong><br/>Public mint is active.</div>} tag={<Box className="d-flex"><Button variant="contained" className="modal-open-btn center" href="/mint">Mint</Button></Box>} />
                            </Stack>
                        </AttentionSeeker>
                        <Scroll to="preview" />
                    </div>
                </div>
                <div id="preview" className="section">
                    <div>
                        <Fade><h1 className="center">Our vision.</h1></Fade>
                        <ImageList className="desktop" sx={{ width: 760, height: 760 }} cols={2} gap={2} >
                            {crusader_images.map((item) => (
                                <ImageListItem key={item}>
                                    <img
                                        src={`${item}?w=164&h=164&fit=crop&auto=format`}
                                        srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        alt=""
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                        <Stack className="stack mobile" direction="column" alignItems="center" justifyContent="center" spacing={1}>
                            {crusader_images.map( (item) => (
                                <img
                                    src={item}
                                    srcSet={item}
                                    alt=""
                                    loading="lazy"
                                />
                            ))}
                        </Stack>
                        <Scroll to="contact" />
                    </div>
                </div>
                <div id="contact" className="section">
                    <div>
                        <Fade><h1 className="center">Follow us on social media and join the crusade!</h1></Fade>
                        <AttentionSeeker effect="tada">
                            <Stack className="stack" direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                <IconLink Icon={DiscordIcon} link="https://discord.gg/cjYHx4peAG" />
                                <IconLink Icon={TwitterIcon} link="https://twitter.com/crusadersnft" />
                                <IconLink Icon={InstaIcon} link="https://www.instagram.com/blockchain_crusaders/" />
                            </Stack>
                        </AttentionSeeker>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Landing
